import React from "react";
import { PluginActionToolbar } from "PluginActionEditor";
import DeleteModuleMenuItem from "ee/pages/Editor/ModuleEditor/ModulePluginActionEditor/components/DeleteModuleMenuItem";
import { useSaveModuleActionName } from "../hooks/useSaveModuleActionName";
import { PluginActionNameEditor } from "PluginActionEditor";

const ModulePluginActionToolbar = () => {
  const { onSaveActionName } = useSaveModuleActionName();

  return (
    <PluginActionToolbar menuContent={<DeleteModuleMenuItem />}>
      <PluginActionNameEditor saveActionName={onSaveActionName} />
    </PluginActionToolbar>
  );
};

export default ModulePluginActionToolbar;
