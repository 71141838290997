import React from "react";
import { PluginActionToolbar } from "PluginActionEditor";
import DeleteWorkflowActionMenuItem from "ee/pages/Editor/WorkflowEditor/WorkflowPluginActionEditor/components/DeleteWorkflowMenuItem";
import { saveWorkflowActionName } from "ee/actions/workflowActions";
import { PluginActionNameEditor } from "PluginActionEditor";

const WorkflowPluginActionToolbar = () => {
  return (
    <PluginActionToolbar menuContent={<DeleteWorkflowActionMenuItem />}>
      <PluginActionNameEditor saveActionName={saveWorkflowActionName} />
    </PluginActionToolbar>
  );
};

export default WorkflowPluginActionToolbar;
